import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { FaPen, FaAngleLeft, FaAngleRight, FaVolumeOff } from "react-icons/fa"
import {
    MELOGY_TRANSPARENT,
    SECONDARY,
    SECONDARY_5XDIM,
} from "../../style/colors"
import Button from "../Button"
import Popover from "../popover/Popover"
import { categoriesAndInstruments } from "../../sounds/CategoriesAndInstruments"
import {
    BUTTON_FONT_FAMILY,
    BUTTON_FONT_WEIGHT,
    BUTTON_LETTER_SPACING,
    FONT_SIZE_2XS,
    FONT_SIZE_6XS,
} from "../../style/fontSettings"
import PopoverContentContainer from "../popover/PopoverContentContainer"
import {
    selectNextInstrument,
    selectPreviousInstrument,
} from "../../util/TrackUtil"
import VolumeSlider from "../slider/VolumeSlider"

const TrackContainer = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${SECONDARY};
    background-color: ${(props) =>
        props.isSelected ? `${SECONDARY_5XDIM}` : `${MELOGY_TRANSPARENT}`};
`

const TrackNumber = styled.div`
    font-family: ${BUTTON_FONT_FAMILY};
    letter-spacing: ${BUTTON_LETTER_SPACING};
    font-weight: ${BUTTON_FONT_WEIGHT};
    font-size: ${FONT_SIZE_2XS};
    color: ${SECONDARY};
    height: 64px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`

const VolumeDisplay = styled.div`
    width: 32px;
    height: 32px;
    font-family: ${BUTTON_FONT_FAMILY};
    letter-spacing: ${BUTTON_LETTER_SPACING};
    font-weight: ${BUTTON_FONT_WEIGHT};
    font-size: ${FONT_SIZE_6XS};
    display: flex;
    align-items: center;
    justify-content: center;
`

function Track({
    trackNumber,
    selectedInstrument,
    selectInstrument,
    isScrollVisible,
    handleVolumeChange,
}) {
    const trackRef = useRef(null)
    const [isSelected, setIsSelected] = useState(false)
    const [selectCategoryPopoverIsOpen, setSelectCategoryPopover] =
        useState(false)
    const [selectInstrumentPopoverIsOpen, setSelectInstrumentPopover] =
        useState(false)
    const [trackVolumePopoverIsOpen, setTrackVolumePopover] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [instrumentButtonWidth, setInstrumentButtonWidth] = useState("96px")
    const categories = categoriesAndInstruments.map((item) => item.category)
    const instrumentsOfSelectedCategory = categoriesAndInstruments
        .filter((item) => item.category === selectedCategory)
        .reduce((acc, curr) => [...acc, ...curr.instrument], [])

    const toggleSelectCategoryPopover = () => {
        console.log("toggleSelectCategoryPopover")
        setSelectCategoryPopover((prev) => !prev)
    }

    const toggleSelectInstrumentPopover = () => {
        console.log("toggleSelectInstrumentPopover")
        setSelectInstrumentPopover((prev) => !prev)
    }

    const selectCategory = (event) => {
        const category = event.target.innerText
        if (category) {
            setSelectedCategory(category)
            setSelectInstrumentPopover(true)
        }
    }

    const handleSelectInstrument = (event) => {
        const instrumentName = event.target.innerText

        if (instrumentName) {
            selectInstrument(instrumentName, trackNumber, selectedCategory)
            setSelectInstrumentPopover(false)
            setSelectCategoryPopover(false)
        }
    }

    const toggleTrackVolumePopover = () => {
        console.log("toggleTrackVolumePopover")
        setTrackVolumePopover((prev) => !prev)
    }

    useEffect(() => {
        const updateWidth = () => {
            const screenWidth = window.innerWidth
            const calculatedWidth = Math.min(screenWidth * 0.4, 168)
            setInstrumentButtonWidth(`${calculatedWidth}px`)
        }

        window.addEventListener("resize", updateWidth)
        updateWidth()

        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    useEffect(() => {
        if (selectedInstrument) {
            const foundCategory = categoriesAndInstruments.find(
                (categoryItem) =>
                    categoryItem.instrument.includes(selectedInstrument.name)
            )

            if (foundCategory) {
                setSelectedCategory(foundCategory.category)
            }
        }
    }, [selectedInstrument])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                selectCategoryPopoverIsOpen ||
                selectInstrumentPopoverIsOpen ||
                trackVolumePopoverIsOpen
            ) {
                // If any popover is open, clicking outside shouldn't unselect the track
                return
            }

            if (trackRef.current && !trackRef.current.contains(event.target)) {
                setIsSelected(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [
        selectCategoryPopoverIsOpen,
        selectInstrumentPopoverIsOpen,
        trackVolumePopoverIsOpen,
    ])

    const handleTrackClick = () => {
        setIsSelected(true)
    }

    return (
        <>
            <TrackContainer
                ref={trackRef}
                onClick={handleTrackClick}
                isSelected={isSelected}
            >
                <TrackNumber>{trackNumber}</TrackNumber>
                <Button
                    height="32px"
                    width="32px"
                    justifyContent="flex-start"
                    buttonColor="secondary"
                    icon={<FaVolumeOff style={{ fontSize: "16px" }} />}
                    onClick={toggleTrackVolumePopover}
                />
                {/* <VolumeDisplay>
                    {Math.round(selectedInstrument.volume * 400.0)}
                </VolumeDisplay> */}
                <Button
                    height="32px"
                    width="32px"
                    justifyContent="flex-start"
                    buttonColor="secondary"
                    icon={<FaAngleLeft style={{ fontSize: "16px" }} />}
                    onClick={() =>
                        selectPreviousInstrument(
                            categoriesAndInstruments,
                            selectedInstrument,
                            setSelectedCategory,
                            selectInstrument,
                            trackNumber
                        )
                    }
                />
                <Button
                    text={selectedInstrument.name}
                    height="32px"
                    width={instrumentButtonWidth}
                    justifyContent="flex-start"
                    isSelected={
                        selectCategoryPopoverIsOpen ||
                        selectInstrumentPopoverIsOpen
                    }
                    buttonColor="secondary"
                    onClick={toggleSelectCategoryPopover}
                />
                <Button
                    height="32px"
                    width="32px"
                    justifyContent="flex-start"
                    buttonColor="secondary"
                    icon={<FaAngleRight style={{ fontSize: "16px" }} />}
                    onClick={() =>
                        selectNextInstrument(
                            categoriesAndInstruments,
                            selectedInstrument,
                            setSelectedCategory,
                            selectInstrument,
                            trackNumber
                        )
                    }
                />
                <Button
                    height="32px"
                    width="32px"
                    justifyContent="flex-start"
                    buttonColor="secondary"
                    onClick={() => console.log("Edit track")}
                    icon={<FaPen style={{ fontSize: "16px" }} />}
                    margin={`0 ${isScrollVisible ? "16px" : "0"} 0 auto`}
                />
            </TrackContainer>
            {selectCategoryPopoverIsOpen && !selectInstrumentPopoverIsOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title={`TRACK ${trackNumber}`}
                            list={categories}
                            onClick={selectCategory}
                            selectedListItem={selectedCategory}
                        />
                    }
                    onClose={toggleSelectCategoryPopover}
                    zIndex={1002}
                />
            )}
            {selectInstrumentPopoverIsOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title={selectedCategory}
                            list={instrumentsOfSelectedCategory}
                            onClick={handleSelectInstrument}
                            selectedListItem={selectedInstrument.name}
                        />
                    }
                    onClose={toggleSelectInstrumentPopover}
                    zIndex={1004}
                />
            )}
            {trackVolumePopoverIsOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title={`TRACK ${trackNumber}`}
                            component={
                                <VolumeSlider
                                    selectedInstrument={selectedInstrument}
                                    handleVolumeChange={handleVolumeChange}
                                    trackNumber={trackNumber}
                                />
                            }
                        />
                    }
                    onClose={toggleTrackVolumePopover}
                    zIndex={1002}
                />
            )}
        </>
    )
}

export default Track
