import React from "react"
import styled from "styled-components"
import { SECONDARY } from "../../../style/colors"
import { FONT_SIZE_2XS } from "../../../style/fontSettings"

const Text = styled.div`
    max-width: min(calc(80vw - 32px), calc(480px - 32px));
    font-size: ${FONT_SIZE_2XS};
    letter-spacing: 0.2px;
    color: ${SECONDARY};
    user-select: none;
`

const EmptySpace = styled.div`
    max-width: min(calc(80vw - 32px), calc(480px - 32px));
    height: 16px;
`

const VideoContainer = styled.div`
    max-width: min(calc(80vw - 32px), calc(480px - 32px));
    display: flex;
    align-items: center;
    justify-content: center;
    height: 192px;
    min-height: 192px;
    width: 100%;
`

const StyledVideo = styled.video`
    width: 108px;
    height: 192px;
`

const AboutPopoverContainer = styled.div`
    max-height: calc(
        min(80vh, 480px) - 64px
    ); // 80% of viewport height minus padding and title height

    overflow-y: auto; // Make it scrollable

    /* Hide scrollbar */
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const AboutPopoverContent = () => {
    const videoRef = React.useRef(null)

    const togglePlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play()
            } else {
                videoRef.current.pause()
            }
        }
    }

    return (
        <AboutPopoverContainer>
            <VideoContainer onClick={togglePlay}>
                <StyledVideo ref={videoRef} muted>
                    <source
                        src={process.env.REACT_APP_ABOUT_VIDEO_URL}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </StyledVideo>
            </VideoContainer>
            <EmptySpace />
            <Text>
                I'm Mikko Virtala, a programmer and composer from Helsinki.
            </Text>
            <EmptySpace />
            <Text>
                Melogy is a music generation tool. It uses a combination of user
                input, algorithmic randomization, and music theory to create
                unique compositions.
            </Text>
            <EmptySpace />
            <Text>
                The goal of Melogy is to simplify the exploration and creation
                of new musical styles. It's a practical tool for both music
                enthusiasts and creators, offering an intuitive platform for
                musical experimentation.
            </Text>
            <EmptySpace />
            <Text>
                All music generated and downloaded through Melogy is fully yours
                to use and adapt in any way you see fit.
            </Text>
        </AboutPopoverContainer>
    )
}

export default AboutPopoverContent
