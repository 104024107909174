import React from "react"
import styled from "styled-components"
import { SECONDARY } from "../../../style/colors"
import {
    FONT_SIZE_2XS,
    CODE_FONT_FAMILY,
    CODE_FONT_WEIGHT,
    CODE_LETTER_SPACING,
} from "../../../style/fontSettings"
import { showToastSuccess } from "../../../style/ShowToast"
import { FaCopy } from "react-icons/fa"
import Button from "../../Button"

const Text = styled.div`
    max-width: min(calc(80vw - 32px), calc(480px - 32px));
    font-size: ${FONT_SIZE_2XS};
    letter-spacing: 0.2px;
    color: ${SECONDARY};
    user-select: none;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: min(calc(80vw - 32px), calc(480px - 32px));
    margin-bottom: 8px;
    margin-top: 8px;
`

const ContactPopoverContent = () => {
    const emailAddress = "mikko.melogy@gmail.com"

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(emailAddress)
        showToastSuccess(`Email address copied to clipboard!`, 5000)
    }

    return (
        <>
            <Text>
                If you've got questions or suggestions, don't hesitate to reach
                out. I love discussing ideas, hearing feedback, and
                collaborating on new projects.
            </Text>
            <ButtonContainer>
                <Button
                    text={emailAddress}
                    fontSize={FONT_SIZE_2XS}
                    fontFamily={CODE_FONT_FAMILY}
                    fontWeight={CODE_FONT_WEIGHT}
                    height="32px"
                    width="100%"
                    justifyContent="flex-start"
                    buttonColor="secondary"
                    onClick={handleCopyToClipboard}
                    icon={<FaCopy style={{ fontSize: "16px" }} />}
                    letterSpacing={CODE_LETTER_SPACING}
                />
            </ButtonContainer>
        </>
    )
}

export default ContactPopoverContent
