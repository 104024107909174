import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/system";

const SlightEaseLinearProgress = styled(LinearProgress)`
  & .MuiLinearProgress-bar {
    transition: transform 200ms linear;
  }
`;

export default function LinearDeterminate({ progress }) {
  return (
    <Box sx={{ width: "100%" }}>
      <SlightEaseLinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
