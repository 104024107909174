export const selectNextInstrument = (
    categoriesAndInstruments,
    selectedInstrument,
    setSelectedCategory,
    selectInstrument,
    trackNumber
) => {
    const currentCategoryIndex = categoriesAndInstruments.findIndex(
        (category) => category.instrument.includes(selectedInstrument.name)
    )

    const currentInstruments =
        categoriesAndInstruments[currentCategoryIndex].instrument
    const currentInstrumentIndex = currentInstruments.indexOf(
        selectedInstrument.name
    )

    let nextInstrumentName, nextCategoryIndex

    if (currentInstrumentIndex < currentInstruments.length - 1) {
        nextInstrumentName = currentInstruments[currentInstrumentIndex + 1]
        nextCategoryIndex = currentCategoryIndex
    } else {
        nextCategoryIndex =
            (currentCategoryIndex + 1) % categoriesAndInstruments.length
        nextInstrumentName =
            categoriesAndInstruments[nextCategoryIndex].instrument[0]
    }

    const nextCategory = categoriesAndInstruments[nextCategoryIndex].category

    if (
        nextCategory !== categoriesAndInstruments[currentCategoryIndex].category
    ) {
        setSelectedCategory(nextCategory)
    }

    selectInstrument(nextInstrumentName, trackNumber, nextCategory)
}

export const selectPreviousInstrument = (
    categoriesAndInstruments,
    selectedInstrument,
    setSelectedCategory,
    selectInstrument,
    trackNumber
) => {
    let currentCategoryIndex = categoriesAndInstruments.findIndex((category) =>
        category.instrument.includes(selectedInstrument.name)
    )

    const currentInstruments =
        categoriesAndInstruments[currentCategoryIndex].instrument
    const currentInstrumentIndex = currentInstruments.indexOf(
        selectedInstrument.name
    )

    let previousInstrumentName, previousCategoryIndex

    if (currentInstrumentIndex > 0) {
        // Select the previous instrument in the current category
        previousInstrumentName = currentInstruments[currentInstrumentIndex - 1]
        previousCategoryIndex = currentCategoryIndex
    } else {
        // Move to the previous category and select its last instrument
        previousCategoryIndex =
            (currentCategoryIndex - 1 + categoriesAndInstruments.length) %
            categoriesAndInstruments.length
        previousInstrumentName =
            categoriesAndInstruments[previousCategoryIndex].instrument[
                categoriesAndInstruments[previousCategoryIndex].instrument
                    .length - 1
            ]
    }

    const previousCategory =
        categoriesAndInstruments[previousCategoryIndex].category

    // Update the selected category if it has changed
    if (
        previousCategory !==
        categoriesAndInstruments[currentCategoryIndex].category
    ) {
        setSelectedCategory(previousCategory)
    }

    // Call the selectInstrument function with the new instrument name
    selectInstrument(previousInstrumentName, trackNumber, previousCategory)
}
