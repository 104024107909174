import React from "react"

const QuarterNoteIcon = () => {
    const fillColor = "rgba(58, 248, 248, 1)" // Set the color directly

    return (
        <svg
            version="1.1"
            id="_x32_"
            xmlns="http://www.w3.org/2000/svg"
            width="16px"
            height="16px"
            viewBox="0 0 512 512"
        >
            <g>
                <path
                    fill={fillColor}
                    d="M340.078-0.004v311.078c-23.641-9.969-52.828-14.047-84.422-10.125
                    c-78.516,9.75-141.969,64.594-141.766,122.407c0.203,57.875,64,96.906,142.469,87.156c78.5-9.766,141.953-64.594,141.75-122.469
                    V-0.004H340.078z"
                />
            </g>
        </svg>
    )
}

export default QuarterNoteIcon
