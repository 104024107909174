import styled from "styled-components"
import { ToastContainer } from "react-toastify"
import {
    MELOGY_BACKGROUND_MEDIUM_LIGHT_GREY,
    SECONDARY,
    SECONDARY_DIM,
    SECONDARY_XDIM,
} from "./colors"

export const StyledToastContainer = styled(ToastContainer)`
    &.Toastify__toast-container {
        max-width: 248px;
        padding: 0 !important;
        top: 16px !important;
        right: 16px !important;

        /* Add media query for smaller screens */
        @media only screen and (max-width: 480px) {
            width: 100vw;
            margin-right: 0 !important;
            margin-left: auto;
            display: flex; /* Flexbox */
            justify-content: center; /* Center horizontally */
        }
    }

    .Toastify__toast {
        background-color: ${MELOGY_BACKGROUND_MEDIUM_LIGHT_GREY};
        color: ${SECONDARY};
        border-radius: 0 !important;
        top: 16px;
        right: 16px;
    }

    .Toastify__close-button {
        color: ${SECONDARY};
        opacity: 1;

        /* Styles for hover state */
        &:hover {
            color: ${SECONDARY_DIM};
        }

        /* Styles for active (click) state */
        &:active {
            color: ${SECONDARY_XDIM};
        }
    }

    /* Style for info toasts */
    .Toastify__toast--info .Toastify__progress-bar {
        background: ${SECONDARY};
    }

    /* Style for success and error toasts to hide progress bar */
    .Toastify__toast--info .Toastify__progress-bar,
    .Toastify__toast--success .Toastify__progress-bar,
    .Toastify__toast--error .Toastify__progress-bar {
        display: none !important;
    }

    .Toastify__toast-theme--light {
        top: 0;
        right: 0;
    }
`
