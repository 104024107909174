import { Midi } from "@tonejs/midi"
import { showToastError } from "../style/ShowToast"
import { defaultStyleSettings } from "../style/defaultStyleSettings"

export const createAndFetchMidi = async (
    setProgress,
    setSong,
    styleSettings
) => {
    const MAX_RETRIES = 2 // Define the maximum number of retries
    let retryCount = 0 // Counter to track the number of attempts
    let progress = 0 // Variable to keep track of the progress
    const data = getPostData(styleSettings)
    console.log("data: ", data)
    const apiAddress = process.env.REACT_APP_API_ADDRESS
    const url = apiAddress //`${apiAddress}?key=0`

    // Function to attempt fetching the MIDI data
    const attemptFetch = async () => {
        // const intervalId = handleInitialProgress(setProgress, progress)
        const { clearProgress } = handleInitialProgress(setProgress, progress)

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })

        const midiBlob = await response.blob()

        const arrayBuffer = await midiBlob.arrayBuffer()

        const fetchedMidi = new Midi(arrayBuffer)
        setSong(fetchedMidi)

        clearProgress()
        setProgress(100)

        await delay(1000)
        setProgress(0)
    }

    // Retry loop: Try to fetch MIDI data until successful or retries are exhausted
    while (retryCount <= MAX_RETRIES) {
        try {
            await attemptFetch() // Try to fetch the MIDI data
            break // Exit the loop if successful
        } catch (error) {
            console.error("Error fetching MIDI from backend:", error)
            retryCount++ // Increment the retry counter

            // Show an error message if all retries have been exhausted
            if (retryCount > MAX_RETRIES) {
                showToastError("Failed to fetch MIDI from backend.", 5000)
                await delay(1000)
                setProgress(0)
            }
        }
    }
}

// const getPostData = (styleSettings) => {
//     const postData = {}

//     let keyValue = styleSettings.find((s) => s.name === "key").value

//     styleSettings.forEach((setting) => {
//         if (setting.inputType === "SLIDER") {
//             if (setting.name === "songStructure") {
//                 postData[setting.name] = getPostDataForSongStructure(setting)
//             } else if (setting.name === "key") {
//                 postData[setting.name] = getPostDataForKey(setting)
//             } else if (setting.name === "mode") {
//                 if (
//                     styleSettings.find((s) => s.name === "key").value ===
//                     "Random"
//                 ) {
//                     keyValue = postData["key"]
//                     const keyNameInPossibleValues = styleSettings.find(
//                         (s) => s.name === "key"
//                     ).possibleValues[keyValue]
//                     const modeOptionsForRandomSelectedKey =
//                         setting.possibleValues.find(
//                             (p) => p.key === keyNameInPossibleValues
//                         ).values
//                     const randomIndex = Math.floor(
//                         Math.random() * modeOptionsForRandomSelectedKey.length
//                     )
//                     postData[setting.name] = randomIndex
//                 } else {
//                     const modeOptions = setting.possibleValues.find(
//                         (p) => p.key === keyValue
//                     )
//                     postData[setting.name] = modeOptions.values.indexOf(
//                         setting.value
//                     )
//                 }
//             } else {
//                 postData[setting.name] = setting.value
//             }
//         } else if (setting.inputType === "RANGE_SLIDER") {
//             postData[setting.name] = [setting.lowerValue, setting.higherValue]
//         }
//     })

//     return postData
// }

const getPostData = (styleSettings) => {
    const postData = {}

    styleSettings.forEach((setting) => {
        if (setting.inputType === "SLIDER") {
            if (setting.name === "songStructure") {
                postData[setting.name] = getPostDataForSongStructure(setting)
            } else if (setting.name === "root") {
                postData[setting.name] = getPostDataForRoot(setting)
            } else if (setting.name === "key") {
                postData[setting.name] = getPostDataForKey(setting)
            } else if (setting.name === "mode") {
                postData[setting.name] = getPostDataForMode(
                    setting,
                    styleSettings
                )
            } else if (setting.name === "overall extended chord rate") {
                postData["overallExtendedChordRate"] =
                    getPostDataForOverallExtendedChordRate(setting)
            } else if (
                setting.name === "7th chord rate" ||
                setting.name === "9th chord rate" ||
                setting.name === "11th chord rate" ||
                setting.name === "13th chord rate"
            ) {
                postData[getNameOfChordExtension(setting)] =
                    getPostDataForChordExtensions(setting)
            } else {
                postData[setting.name] = setting.value
            }
        } else if (setting.inputType === "RANGE_SLIDER") {
            postData[setting.name] = [setting.lowerValue, setting.higherValue]
        }
    })

    return postData
}

const getNameOfChordExtension = (chordExtensionSetting) => {
    if (chordExtensionSetting.name === "7th chord rate") {
        return "chordRateOf7th"
    } else if (chordExtensionSetting.name === "9th chord rate") {
        return "chordRateOf9th"
    } else if (chordExtensionSetting.name === "11th chord rate") {
        return "chordRateOf11th"
    } else if (chordExtensionSetting.name === "13th chord rate") {
        return "chordRateOf13th"
    }

    return ""
}

const getPostDataForRoot = (keySetting) => {
    let index
    if (keySetting.value === "Random") {
        const nonRandomValues = keySetting.possibleValues.filter(
            (val) => val !== "Random"
        )
        const randomIndex = Math.floor(Math.random() * nonRandomValues.length)
        index =
            keySetting.possibleValues.indexOf(nonRandomValues[randomIndex]) - 1
    } else {
        index = keySetting.possibleValues.indexOf(keySetting.value) - 1
    }

    if (index > 6) {
        index -= 12
    }

    return index
}

const getPostDataForKey = (keySetting) => {
    if (keySetting.value === "Random") {
        const nonRandomValues = keySetting.possibleValues.filter(
            (val) => val !== "Random"
        )
        const randomIndex = Math.floor(Math.random() * nonRandomValues.length)
        return (
            keySetting.possibleValues.indexOf(nonRandomValues[randomIndex]) - 1
        )
    } else {
        return keySetting.possibleValues.indexOf(keySetting.value) - 1
    }
}

const getPostDataForSongStructure = (songStructureSetting) => {
    if (songStructureSetting.value === "Random") {
        const nonRandomValues = songStructureSetting.possibleValues.filter(
            (val) => val !== "Random"
        )
        const randomIndex = Math.floor(Math.random() * nonRandomValues.length)
        return nonRandomValues[randomIndex]
    } else {
        return songStructureSetting.value
    }
}

const getPostDataForOverallExtendedChordRate = (
    overallExtendedChordRateSetting
) => {
    if (overallExtendedChordRateSetting.value === "Random") {
        const nonRandomValues =
            overallExtendedChordRateSetting.possibleValues.filter(
                (val) => val !== "Random"
            )
        const randomIndex = Math.floor(Math.random() * nonRandomValues.length)
        return parseInt(nonRandomValues[randomIndex])
    } else {
        return parseInt(overallExtendedChordRateSetting.value)
    }
}

const getPostDataForChordExtensions = (chordExtensionSetting) => {
    if (chordExtensionSetting.value === "Random") {
        const nonRandomValues = chordExtensionSetting.possibleValues.filter(
            (val) => val !== "Random"
        )
        const randomIndex = Math.floor(Math.random() * nonRandomValues.length)
        return parseInt(nonRandomValues[randomIndex])
    } else {
        return parseInt(chordExtensionSetting.value)
    }
}

const getPostDataForMode = (modeSetting, styleSettings) => {
    let keyValue = styleSettings.find((s) => s.name === "key").value

    if (keyValue === "Random") {
        keyValue = styleSettings.find((s) => s.name === "key").value
        const keyNameInPossibleValues = styleSettings.find(
            (s) => s.name === "key"
        ).possibleValues[keyValue]
        const modeOptionsForRandomSelectedKey = modeSetting.possibleValues.find(
            (p) => p.key === keyNameInPossibleValues
        ).values
        const randomIndex = Math.floor(
            Math.random() * modeOptionsForRandomSelectedKey.length
        )
        return randomIndex
    } else {
        const modeOptions = modeSetting.possibleValues.find(
            (p) => p.key === keyValue
        )
        return modeOptions.values.indexOf(modeSetting.value)
    }
}

export const setViewportHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const handleInitialProgress = (setProgress) => {
    let initialProgress = 0
    let delay = 75 // Initial delay
    let timeoutId = null // Store the timeout ID

    const increaseProgress = () => {
        initialProgress += 1
        setProgress(initialProgress)

        // Adjust the delay based on progress thresholds
        if (initialProgress >= 95) {
            delay = 250 // Slowest delay after 95
        } else if (initialProgress >= 90) {
            delay = 175 // Slower delay after 90
        } else if (initialProgress >= 85) {
            delay = 125 // Slower delay after 85
        } else if (initialProgress >= 80) {
            delay = 100 // Slower delay after 80
        }

        // Continue increasing progress until 100
        if (initialProgress < 100) {
            timeoutId = setTimeout(increaseProgress, delay)
        }
    }

    // Start the progress increase
    timeoutId = setTimeout(increaseProgress, delay)

    // Function to stop the progress
    const clearProgress = () => {
        clearTimeout(timeoutId)
    }

    return { clearProgress }
}

export const updateKeySetting = (currentSettings, newKeyValue) => {
    const currentModeSetting = findSettingByName(currentSettings, "mode")
    const modesOfNewKey = getModesByKey(newKeyValue)
    const currentModeValue = currentModeSetting.value
    const modesOfCurrentKey = getModesByKey(currentModeSetting.key)
    const indexOfCurrentModeValue = modesOfCurrentKey.indexOf(currentModeValue)
    const newModeValue =
        modesOfNewKey[indexOfCurrentModeValue] || modesOfNewKey[0]

    return currentSettings.map((setting) => {
        if (setting.name === "key") {
            return { ...setting, value: newKeyValue }
        } else if (setting.name === "mode") {
            return { ...setting, value: newModeValue, key: newKeyValue }
        }
        return setting
    })
}

const findSettingByName = (settings, name) =>
    settings.find((setting) => setting.name === name)

const getModesByKey = (key) => {
    const modeSetting = defaultStyleSettings.find(
        (setting) => setting.name === "mode"
    )
    return modeSetting.possibleValues.find((pv) => pv.key === key)?.values || []
}
