export const KALIMBA = {
    harmonicity: 8,
    modulationIndex: 2,
    oscillator: {
        type: "sine",
    },
    envelope: {
        attack: 0.001,
        decay: 2,
        sustain: 0.1,
        release: 2,
    },
    modulation: {
        type: "square",
    },
    modulationEnvelope: {
        attack: 0.002,
        decay: 0.2,
        sustain: 0,
        release: 0.2,
    },
}

export const ELECTRIC_CELLO = {
    harmonicity: 3.01,
    modulationIndex: 14,
    oscillator: {
        type: "triangle",
    },
    envelope: {
        attack: 0.2,
        decay: 0.3,
        sustain: 0.1,
        release: 1.2,
    },
    modulation: {
        type: "square",
    },
    modulationEnvelope: {
        attack: 0.01,
        decay: 0.5,
        sustain: 0.2,
        release: 0.1,
    },
}

export const THIN_SAWS = {
    harmonicity: 0.5,
    modulationIndex: 1.2,
    oscillator: {
        type: "fmsawtooth",
        modulationType: "sine",
        modulationIndex: 20,
        harmonicity: 3,
    },
    envelope: {
        attack: 0.05,
        decay: 0.3,
        sustain: 0.1,
        release: 1.2,
    },
    modulation: {
        volume: 0,
        type: "triangle",
    },
    modulationEnvelope: {
        attack: 0.35,
        decay: 0.1,
        sustain: 1,
        release: 0.01,
    },
}

export const ROUGH_FLY = {
    harmonicity: 1.5,
    modulationIndex: 1.2,
    oscillator: {
        type: "sawtooth",
        modulationType: "sine",
        modulationIndex: 40,
        harmonicity: 13,
    },
    envelope: {
        attack: 0.05,
        decay: 0.3,
        sustain: 0.1,
        release: 1.2,
    },
    modulation: {
        volume: 10,
        type: "triangle",
    },
    modulationEnvelope: {
        attack: 10,
        decay: 2.1,
        sustain: 1,
        release: 0.01,
    },
}

export const ROUGH_MAN = {
    harmonicity: 1.5,
    modulationIndex: 1.2,
    oscillator: {
        type: "square",
        modulationType: "sine",
        modulationIndex: 40,
        harmonicity: 13,
    },
    envelope: {
        attack: 0.05,
        decay: 0.3,
        sustain: 0.1,
        release: 1.2,
    },
    modulation: {
        volume: 10,
        type: "triangle",
    },
    modulationEnvelope: {
        attack: 0.1,
        decay: 1.1,
        sustain: 1,
        release: 0.01,
    },
}

export const ROUGH_BUILDING = {
    harmonicity: 1.5,
    modulationIndex: 1.2,
    oscillator: {
        type: "square",
        modulationType: "sine",
        modulationIndex: 40,
        harmonicity: 13,
    },
    envelope: {
        attack: 0.05,
        decay: 0.8,
        sustain: 0.1,
        release: 1.2,
    },
    modulation: {
        volume: 10,
        type: "triangle",
    },
    modulationEnvelope: {
        attack: 0.1,
        decay: 1.1,
        sustain: 1,
        release: 0.01,
    },
}
