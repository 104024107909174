import { createTheme } from "@mui/material/styles"
import {
    SECONDARY,
    MELOGY_BACKGROUND_XDARK_GREY,
    TERTIARY,
    TERTIARY_4XDIM,
    SECONDARY_4XDIM,
} from "../style/colors"
import { FONT_SIZE_2XS } from "../style/fontSettings"

const theme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: 0,
                    backgroundColor: `${SECONDARY}`,
                },
                list: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                    m: 1,
                    minWidth: 120,
                    borderRadius: "0px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        boxShadow: "none",
                    },
                    "& .radio-group": {
                        width: "120px",
                        maxWidth: "120px",
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: `${SECONDARY}`,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    height: "32px",
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: `${SECONDARY}`,
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    flexDirection: "row",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    display: "none",
                },
                select: {
                    padding: 0,
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                },
                root: {
                    fontSize: `${FONT_SIZE_2XS}`,
                    color: `${SECONDARY}`,
                    borderRadius: 0,
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                        borderRadius: 0,
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: `${TERTIARY_4XDIM}`,
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: `${TERTIARY}`,
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                track: {
                    backgroundColor: `${SECONDARY}`,
                    borderRadius: 0,
                    border: "none",
                },
                rail: {
                    backgroundColor: `${SECONDARY_4XDIM}`,
                    borderRadius: 0,
                    opacity: 1,
                },
                valueLabel: {
                    "& .MuiSlider-valueLabelLabel": {
                        fontSize: "1rem",
                        backgroundColor: `${MELOGY_BACKGROUND_XDARK_GREY}`,
                        color: `${SECONDARY}`,
                        "& span": {
                            backgroundColor: `${MELOGY_BACKGROUND_XDARK_GREY}`,
                        },
                    },
                    "&::before": {
                        backgroundColor: `${MELOGY_BACKGROUND_XDARK_GREY}`,
                        display: "none",
                    },
                },
                root: {
                    padding: 0,
                    "& .MuiSlider-thumb": {
                        backgroundColor: `${SECONDARY}`,
                        "& .MuiSlider-valueLabel": {
                            height: "32px",
                            backgroundColor: `${MELOGY_BACKGROUND_XDARK_GREY}`,
                            borderRadius: "50px",
                        },
                        "& input": {
                            backgroundColor: `${SECONDARY}`,
                        },
                    },
                    "& .MuiSlider-mark": {
                        color: `${SECONDARY}`,
                    },
                },
            },
        },
    },
})

export default theme
