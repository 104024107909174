import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FaExpandAlt, FaCompressAlt, FaBars, FaCog } from "react-icons/fa"
import { PRIMARY, MELOGY_TRANSPARENT } from "../style/colors"
import { TITLE_FONT_FAMILY } from "../style/fontSettings"
import Button from "./Button"
import { enterFullScreen, exitFullScreen, logoColors } from "../util/NavbarUtil"

const NavbarContainer = styled.div`
    width: 100%;
    height: 32px;
    color: ${PRIMARY};
    background-color: ${MELOGY_TRANSPARENT};
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Logo = styled.div`
    margin-left: 16px;
`

const fontSize = "14px"

const LogoLetter = styled.span`
    font-family: ${TITLE_FONT_FAMILY};
    font-size: ${fontSize};
    user-select: none;
    margin-right: 12px; /* Add spacing between letters */
`

const Navbar = ({ selectedSong }) => {
    const text = "MElOGY"
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [colorIndices, setColorIndices] = useState(
        Array.from({ length: text.length }).map(() => 0)
    )

    // Function to update color and set next interval for a letter at given index
    const updateColor = (index) => {
        setColorIndices((prevIndices) => {
            const newIndices = [...prevIndices]
            newIndices[index] = (newIndices[index] + 1) % logoColors.length
            return newIndices
        })
        setTimeout(() => updateColor(index), Math.random() * 400000 + 100)
    }

    useEffect(() => {
        // Initialize the update cycle for each letter
        Array.from(text).forEach((_, index) => {
            updateColor(index)
        })
        // No cleanup needed since each call to `updateColor` cleans up its own timeout
    }, [logoColors.length, text.length])

    const toggleFullScreen = () => {
        if (isFullScreen) {
            exitFullScreen()
        } else {
            enterFullScreen()
        }
        setIsFullScreen(!isFullScreen)
    }

    return (
        <NavbarContainer>
            <Logo selectedSong={selectedSong}>
                {Array.from(text).map((letter, index) => (
                    <LogoLetter
                        style={{ color: logoColors[colorIndices[index]] }}
                        key={index}
                    >
                        {letter}
                    </LogoLetter>
                ))}
            </Logo>
            {selectedSong && (
                <Button
                    height="32px"
                    width="32px"
                    justifyContent="flex-start"
                    buttonColor="secondary"
                    onClick={toggleFullScreen}
                    icon={
                        isFullScreen ? (
                            <FaCompressAlt style={{ fontSize: "16px" }} />
                        ) : (
                            <FaExpandAlt style={{ fontSize: "16px" }} />
                        )
                    }
                    margin={"0 16px 0 auto"}
                />
            )}
        </NavbarContainer>
    )
}

export default Navbar
