export const categoriesAndInstruments = [
    {
        category: "Samples",
        instrument: [
            "Flute",
            "Piano",
            "Violin",
            "Trumpet",
            "Cello",
            "Guitar acoustic",
            "Guitar electric",
            "Bass electric",
        ],
    },
    {
        category: "Synth",
        instrument: [
            "Alien chorus",
            "Delicate wind part",
            "Drop pulse",
            "Lectric",
            "Marimba",
            "Steelpan",
            "Super saw",
            "Tree trunk",
        ],
    },
    {
        category: "Monosynth",
        instrument: [
            "Bah",
            "Bass guitar",
            "Bassy",
            "Brass circuit",
            "Cool guy",
            "Pianoetta",
            "Pizz",
            "Nice boy",
        ],
    },
    {
        category: "FMSynth",
        instrument: [
            "Kalimba",
            "Electric cello",
            "Thin saws",
            "Rough fly",
            "Rough man",
            "Rough building",
        ],
    },
]
