import React from "react"
import styled from "styled-components"
import { SECONDARY, MELOGY_TRANSPARENT } from "../../style/colors"
import {
    BUTTON_FONT_FAMILY,
    BUTTON_FONT_WEIGHT,
    BUTTON_LETTER_SPACING,
    TITLE_FONT_FAMILY,
    TITLE_LETTER_SPACING,
    TITLE_FONT_WEIGHT,
    FONT_SIZE_2XS,
    FONT_SIZE_4XS,
} from "../../style/fontSettings"
import Slider from "../slider/Slider"
import RangeSlider from "../slider/RangeSlider"
import CheckboxGroup from "../checkbox/CheckboxGroup"
import { getDisplayName } from "../../util/StyleSettingUtil"

const SettingContainer = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: ${SECONDARY};
    background-color: ${MELOGY_TRANSPARENT};
`

const StyledText = styled.div`
    font-family: ${BUTTON_FONT_FAMILY};
    letter-spacing: ${BUTTON_LETTER_SPACING};
    font-weight: ${BUTTON_FONT_WEIGHT};
    font-size: ${FONT_SIZE_2XS};
    color: ${SECONDARY};
    height: 32px;
    line-height: 32px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding-left: 2px;
    display: flex;
    align-items: center;
    user-select: none;
`

const StyledName = styled.span`
    font-size: ${FONT_SIZE_4XS};
    font-family: ${TITLE_FONT_FAMILY};
    font-weight: ${TITLE_FONT_WEIGHT};
    letter-spacing: ${TITLE_LETTER_SPACING};
    margin-right: 4px;
`

const SliderContainer = styled.div`
    height: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const EmptySpace = styled.div`
    height: 16px;
    width: 100%;
`

function StyleSetting({ styleSetting, updateStyleSettings }) {
    const { name, inputType, value, lowerValue, higherValue } = styleSetting

    const getValueText = () => {
        return inputType === "SLIDER" ? value : `${lowerValue} - ${higherValue}`
    }

    return (
        <SettingContainer>
            <StyledText>
                <StyledName>{getDisplayName(name)}:</StyledName>
                {getValueText()}
            </StyledText>
            <SliderContainer>
                {inputType === "SLIDER" ? (
                    <Slider
                        styleSetting={styleSetting}
                        updateStyleSettings={updateStyleSettings}
                    />
                ) : inputType === "RANGE_SLIDER" ? (
                    <RangeSlider
                        styleSetting={styleSetting}
                        updateStyleSettings={updateStyleSettings}
                    />
                ) : inputType === "CHECKBOX_GROUP" ? (
                    <CheckboxGroup
                        styleSetting={styleSetting}
                        updateStyleSettings={updateStyleSettings}
                    />
                ) : null}
            </SliderContainer>
            <EmptySpace />
        </SettingContainer>
    )
}

export default StyleSetting
