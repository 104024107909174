import React from "react";
import { toast } from "react-toastify";
import { SECONDARY } from "./colors.js";
import {
  FaInfo,
  FaCheck,
  FaExclamation,
} from "react-icons/fa";  

export const showToastInfo = (message, delay = null) => {
  return showToastWithDelayAndType(toast.info, message, FaInfo, delay);
};

export const showToastSuccess = (message, delay = null) => {
  return showToastWithDelayAndType(toast.success, message, FaCheck, delay);
};

export const showToastError = (message, delay = null) => {
  return showToastWithDelayAndType(toast.error, message, FaExclamation, delay);
};

const showToastWithDelayAndType = (type, message, icon, delay = null) => {
    let timeoutId = null;
  
    const toastId = type(message, {
      icon: React.createElement(icon, { style: { fill: SECONDARY } }),
    });
  
    if (delay !== null) {
      timeoutId = setTimeout(() => {
        toast.dismiss(toastId);
      }, delay);
    }
  
    const dismiss = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      toast.dismiss(toastId);
    };
  
    return { toastId, dismiss };
  };