import { styled } from "@mui/system"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import {
    PRIMARY,
    MELOGY_TRANSPARENT,
    PRIMARY_3XDIM,
    PRIMARY_HIGHLIGHT,
} from "../../style/colors"

const CustomLinearProgress = styled(LinearProgress)(({ progress }) => ({
    backgroundColor: progress > 0 ? PRIMARY_3XDIM : MELOGY_TRANSPARENT,
    "& .MuiLinearProgress-bar": {
        backgroundColor: progress > 98 ? PRIMARY_HIGHLIGHT : PRIMARY,
    },
}))

function CreateSongProgressBar({ progress }) {
    return (
        <Box
            sx={{
                width: "100%",
                position: "fixed",
                top: "0",
                left: "0",
                zIndex: 999,
            }}
        >
            <CustomLinearProgress
                variant="determinate"
                value={progress}
                progress={progress}
            />
        </Box>
    )
}

export default CreateSongProgressBar
