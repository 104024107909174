import React, { useEffect, useRef, useState } from "react"
import "../App.css"
import styled from "styled-components"
import "react-toastify/dist/ReactToastify.css"
import Settings from "./Settings"
import StyleSettings from "./settings/StyleSettings"
import Player from "./Player"
import Navbar from "./Navbar"
import Tracks from "./track/Tracks"
import Controls from "./Controls"
import {
    SECONDARY,
    SECONDARY_4XDIM,
    SECONDARY_HIGHLIGHT,
} from "../style/colors"

const MainContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const UpperMain = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-height: 489px) {
        flex-grow: 0;
        height: 112px;
    }
`

const CenterMain = styled.div`
    // height: 256px;
    width: 80%;
    max-width: 768px;
    display: flex;
    justify-content: ${({ isScrollVisible }) =>
        isScrollVisible ? "flex-start" : "center"};
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: ${({ isScrollVisible }) =>
        isScrollVisible ? "auto" : "hidden"};

    /* Custom scrollbar styles for WebKit-based browsers */
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: ${SECONDARY_4XDIM};
    }
    &::-webkit-scrollbar-thumb {
        background: ${SECONDARY};
    }
    &::-webkit-scrollbar-thumb:hover {
        background: ${SECONDARY_HIGHLIGHT};
    }
`

const LowerMain = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-height: 489px) {
        flex-grow: 0;
        height: 112px;
    }
`

function Main({
    songs,
    setSelectedSong,
    selectedSong,
    selectedInstruments,
    selectInstrument,
    setSongName,
    isNameUnique,
    createAndFetchMidi,
    toggleLeftSideBar,
    toggleShowStyleSettings,
    showStyleSettings,
    styleSettings,
    updateStyleSettings,
    handleVolumeChange,
}) {
    const initialSongCreatedRef = useRef(false)
    const [isScrollVisible, setIsScrollVisible] = useState(false)
    const centerMainRef = useRef(null)

    useEffect(() => {
        if (songs.length === 0 && !initialSongCreatedRef.current) {
            createAndFetchMidi()
            initialSongCreatedRef.current = true // Update the ref immediately
        }
    }, [songs.length]) // Dependency on songs.length

    const checkScrollVisibility = () => {
        if (centerMainRef.current) {
            const isOverflowing =
                centerMainRef.current.scrollHeight >
                centerMainRef.current.clientHeight
            setIsScrollVisible(isOverflowing)
        }
    }

    useEffect(() => {
        if (centerMainRef.current) {
            checkScrollVisibility()
        }
    }, [centerMainRef.current, showStyleSettings])

    useEffect(() => {
        const handleResize = () => {
            // Use setTimeout to delay the check
            setTimeout(() => {
                checkScrollVisibility()
            }, 10) // Adjust the delay as needed
        }

        window.addEventListener("resize", handleResize)
        window.addEventListener("orientationchange", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("orientationchange", handleResize)
        }
    }, [])

    return (
        <MainContainer>
            <UpperMain>
                <Navbar selectedSong={selectedSong} />
                <Settings
                    selectedInstruments={selectedInstruments}
                    selectInstrument={selectInstrument}
                    selectedSong={selectedSong}
                    setSongName={setSongName}
                    isNameUnique={isNameUnique}
                    styleSettings={styleSettings}
                    updateStyleSettings={updateStyleSettings}
                />
            </UpperMain>
            {selectedSong && (
                <CenterMain
                    ref={centerMainRef}
                    isScrollVisible={isScrollVisible}
                >
                    {showStyleSettings ? (
                        <StyleSettings
                            styleSettings={styleSettings}
                            updateStyleSettings={updateStyleSettings}
                            isScrollVisible={isScrollVisible}
                        />
                    ) : (
                        <Tracks
                            selectedInstruments={selectedInstruments}
                            selectInstrument={selectInstrument}
                            isScrollVisible={isScrollVisible}
                            handleVolumeChange={handleVolumeChange}
                        />
                    )}
                </CenterMain>
            )}
            <LowerMain>
                <Player
                    songs={songs}
                    setSelectedSong={setSelectedSong}
                    selectedInstruments={selectedInstruments}
                    selectedSong={selectedSong}
                    createAndFetchMidi={createAndFetchMidi}
                />
                <Controls
                    songs
                    setSelectedSong
                    selectedSong={selectedSong}
                    selectedInstruments={selectedInstruments}
                    createAndFetchMidi={createAndFetchMidi}
                    toggleLeftSideBar={toggleLeftSideBar}
                    toggleShowStyleSettings={toggleShowStyleSettings}
                />
            </LowerMain>
        </MainContainer>
    )
}

export default Main
