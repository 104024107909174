import * as React from "react"
import styled from "styled-components"
import CircularProgress from "@mui/material/CircularProgress"
import { TERTIARY } from "../../style/colors"

const Container = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`

function CircularProgressBar() {
    return (
        <Container>
            <CircularProgress
                sx={{ color: TERTIARY }}
                size={32}
                thickness={4}
            />
        </Container>
    )
}

export default CircularProgressBar
