import React, { useState, useEffect } from "react"
import Slider from "@mui/material/Slider"
import styled from "styled-components"
import { getPossibleValues } from "../../util/SliderUtil"

const SliderContainer = styled.div`
    height: 16px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    align-items: center;
`

function SingleValueSlider({ styleSetting, updateStyleSettings }) {
    const { name, value } = styleSetting
    console.log("name", name)
    console.log("value", value)
    const possibleValues = getPossibleValues(styleSetting)
    console.log("possibleValues", possibleValues)
    const initialNumberValue = possibleValues.indexOf(value) + 1
    console.log("initialNumberValue", initialNumberValue)
    const [numberValue, setNumberValue] = useState(initialNumberValue)

    useEffect(() => {
        const newNumberValue = possibleValues.indexOf(value) + 1
        setNumberValue(newNumberValue)

        console.log("newNumberValue", newNumberValue)
    }, [value, possibleValues])

    const handleChange = (event, newNumberValue) => {
        const newValue = possibleValues[newNumberValue - 1] // Adjust indexing for array
        setNumberValue(newNumberValue) // Update local state
        updateStyleSettings(name, newValue) // Update settings with the actual value
    }

    return (
        <SliderContainer key={possibleValues.length}>
            <Slider
                getAriaLabel={() => `${name} slider`}
                value={numberValue}
                size="small"
                onChange={handleChange}
                valueLabelDisplay="off"
                step={1}
                marks
                min={1}
                max={possibleValues.length} // Set max based on the length of possibleValues
            />
        </SliderContainer>
    )
}

export default SingleValueSlider
