import React from "react"
import styled from "styled-components"
import {
    FaPlay,
    FaPlus,
    FaPause,
    FaStepBackward,
    FaStepForward,
    FaRedoAlt,
} from "react-icons/fa"
import Button from "./Button"
import CircularProgressBar from "./progressbar/CircularProgressBar"

const PlayerControlsContainer = styled.div`
    height: 48px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`

const IconContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

const PlusIcon = styled(FaPlus)`
    position: absolute;
    left: 11px;
    bottom: 11px;
    font-size: 8px; // Adjust size as needed
`

const PlayerControls = ({
    handleStepBackwardClick,
    handlePlayPauseClick,
    handleStepForwardClick,
    isPlaying,
    handlePlayLoopClick,
    isLooping,
    toggleAutoPlay,
    isAutoPlayEnabled,
    isInitializing,
}) => {
    return (
        <>
            <PlayerControlsContainer>
                <Button
                    height="32px"
                    width="32px"
                    justifyContent="flex-start"
                    buttonColor="tertiary"
                    icon={
                        <IconContainer>
                            <FaPlay style={{ fontSize: "16px" }} />
                            <PlusIcon />
                        </IconContainer>
                    }
                    onClick={toggleAutoPlay}
                    isSelected={isAutoPlayEnabled}
                />
                <Button
                    height="40px"
                    width="40px"
                    justifyContent="flex-start"
                    buttonColor="tertiary"
                    onClick={handleStepBackwardClick}
                    icon={<FaStepBackward style={{ fontSize: "24px" }} />}
                />
                {isInitializing ? (
                    <CircularProgressBar />
                ) : (
                    <Button
                        height="48px"
                        width="48px"
                        justifyContent="flex-start"
                        buttonColor="tertiary"
                        onClick={handlePlayPauseClick}
                        icon={
                            isPlaying ? (
                                <FaPause style={{ fontSize: "32px" }} />
                            ) : (
                                <FaPlay style={{ fontSize: "32px" }} />
                            )
                        }
                    />
                )}
                <Button
                    height="40px"
                    width="40px"
                    justifyContent="flex-start"
                    buttonColor="tertiary"
                    onClick={handleStepForwardClick}
                    icon={<FaStepForward style={{ fontSize: "24px" }} />}
                />
                <Button
                    height="32px"
                    width="32px"
                    justifyContent="flex-start"
                    buttonColor="tertiary"
                    icon={<FaRedoAlt style={{ fontSize: "16px" }} />}
                    onClick={handlePlayLoopClick}
                    isSelected={isLooping}
                />
            </PlayerControlsContainer>
        </>
    )
}

export default PlayerControls
