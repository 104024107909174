export const MELOGY_BLACK = "rgba(0, 0, 0, 1)"
export const MELOGY_BACKGROUND_XDARK_GREY = "rgba(10, 10, 10, 1)"
export const MELOGY_BACKGROUND_DARK_GREY = "rgba(20, 20, 20, 1)"
export const MELOGY_BACKGROUND_MEDIUM_GREY = "rgba(30, 30, 30, 1)"
export const MELOGY_BACKGROUND_MEDIUM_LIGHT_GREY = "rgba(40, 40, 40, 1)"

export const MELOGY_BACKGROUND_LIGHT_GREY = "rgba(180, 180, 180, 1)"
export const MELOGY_BACKGROUND_XLIGHT_GREY = "rgba(190, 190, 190, 1)"
export const MELOGY_BACKGROUND_2XLIGHT_GREY = "rgba(200, 200, 200, 1)"

export const MELOGY_BACKGROUND_XDARK_GREY_DIM = "rgba(0, 0, 0, 0.50)"

export const MELOGY_TRANSPARENT = "rgba(0, 0, 0, 0)"

export const PRIMARY_XHIGHLIGHT = "rgba(251, 251, 137, 1)"
export const SECONDARY_XHIGHLIGHT = "rgba(137, 251, 251, 1)"
export const TERTIARY_XHIGHLIGHT = "rgba(251, 137, 251, 1)"

export const PRIMARY_HIGHLIGHT = "rgba(249, 249, 98, 1)"
export const SECONDARY_HIGHLIGHT = "rgba(98, 249, 249, 1)"
export const TERTIARY_HIGHLIGHT = "rgba(249, 98, 249, 1)"

export const PRIMARY = "rgba(248, 248, 58, 1)"
export const SECONDARY = "rgba(58, 248, 248, 1)"
export const TERTIARY = "rgba(248, 58, 248, 1)"

// export const PRIMARY = "rgba(255, 255, 0, 1)"
// export const SECONDARY = "rgba(0, 255, 255, 1)"
// export const TERTIARY = "rgba(255, 0, 255, 1)"

export const PRIMARY_DIM = "rgba(246, 246, 19, 1)"
export const SECONDARY_DIM = "rgba(19, 246, 246, 1)"
export const TERTIARY_DIM = "rgba(246, 19, 246, 1)"

export const PRIMARY_XDIM = "rgba(217, 217, 8, 1)"
export const SECONDARY_XDIM = "rgba(8, 217, 217, 1)"
export const TERTIARY_XDIM = "rgba(217, 8, 217, 1)"

export const PRIMARY_2XDIM = "rgba(177, 177, 6, 1)"
export const SECONDARY_2XDIM = "rgba(6, 177, 177, 1)"
export const TERTIARY_2XDIM = "rgba(177, 6, 177, 1)"

export const PRIMARY_3XDIM = "rgba(138, 138, 5, 1)"
export const SECONDARY_3XDIM = "rgba(5, 138, 138, 1)"
export const TERTIARY_3XDIM = "rgba(138, 5, 138, 1)"

export const PRIMARY_4XDIM = "rgba(98, 98, 4, 1)"
export const SECONDARY_4XDIM = "rgba(4, 98, 98, 1)"
export const TERTIARY_4XDIM = "rgba(98, 4, 98, 1)"

export const PRIMARY_5XDIM = "rgba(59, 59, 2, 1)"
export const SECONDARY_5XDIM = "rgba(2, 59, 59, 1)"

// export const PRIMARY_XHIGHLIGHT = "rgba(255, 255, 130, 1)"
// export const SECONDARY_XHIGHLIGHT = "rgba(130, 255, 255, 1)"
// export const TERTIARY_XHIGHLIGHT = "rgba(255, 130, 255, 1)"

// export const PRIMARY_HIGHLIGHT = "rgba(235, 235, 110, 1)"
// export const SECONDARY_HIGHLIGHT = "rgba(110, 235, 235, 1)"
// export const TERTIARY_HIGHLIGHT = "rgba(235, 110, 235, 1)"

// export const PRIMARY = "rgba(215, 215, 90, 1)" // Muted Yellow
// export const SECONDARY = "rgba(90, 215, 215, 1)" // Muted Cyan
// export const TERTIARY = "rgba(215, 90, 215, 1)" // Muted Magenta

// export const PRIMARY_DIM = "rgba(195, 195, 70, 1)"
// export const SECONDARY_DIM = "rgba(70, 195, 195, 1)"
// export const TERTIARY_DIM = "rgba(195, 70, 195, 1)"

// export const PRIMARY_XDIM = "rgba(175, 175, 50, 1)"
// export const SECONDARY_XDIM = "rgba(50, 175, 175, 1)"
// export const TERTIARY_XDIM = "rgba(175, 50, 175, 1)"

// export const PRIMARY_2XDIM = "rgba(155, 155, 30, 1)"
// export const SECONDARY_2XDIM = "rgba(30, 155, 155, 1)"
// export const TERTIARY_2XDIM = "rgba(155, 30, 155, 1)"

// export const PRIMARY_3XDIM = "rgba(135, 135, 10, 1)"
// export const SECONDARY_3XDIM = "rgba(10, 135, 135, 1)"
// export const TERTIARY_3XDIM = "rgba(135, 10, 135, 1)"

// export const TERTIARY_4XDIM = "rgba(115, 9, 115, 1)"

// brighter colors

// export const PRIMARY_XHIGHLIGHT = "rgba(247, 247, 182, 1)"
// export const SECONDARY_XHIGHLIGHT = "rgba(182, 247, 247, 1)"
// export const TERTIARY_XHIGHLIGHT = "rgba(247, 182, 247, 1)"

// export const PRIMARY_HIGHLIGHT = "rgba(243, 243, 144, 1)"
// export const SECONDARY_HIGHLIGHT = "rgba(144, 243, 243, 1)"
// export const TERTIARY_HIGHLIGHT = "rgba(243, 144, 243, 1)"

// export const PRIMARY = "rgba(223, 223, 124, 1)" // Muted Yellow
// export const SECONDARY = "rgba(124, 223, 223, 1)" // Muted Cyan
// export const TERTIARY = "rgba(223, 124, 223, 1)" // Muted Magenta

// export const PRIMARY_DIM = "rgba(203, 203, 104, 1)"
// export const SECONDARY_DIM = "rgba(104, 203, 203, 1)"
// export const TERTIARY_DIM = "rgba(203, 104, 203, 1)"

// export const PRIMARY_XDIM = "rgba(183, 183, 84, 1)"
// export const SECONDARY_XDIM = "rgba(84, 183, 183, 1)"
// export const TERTIARY_XDIM = "rgba(183, 84, 183, 1)"

// export const PRIMARY_2XDIM = "rgba(163, 163, 64, 1)"
// export const SECONDARY_2XDIM = "rgba(64, 163, 163, 1)"
// export const TERTIARY_2XDIM = "rgba(163, 64, 163, 1)"

// export const PRIMARY_3XDIM = "rgba(143, 143, 44, 1)"
// export const SECONDARY_3XDIM = "rgba(44, 143, 143, 1)"
// export const TERTIARY_3XDIM = "rgba(143, 44, 143, 1)"

// export const PRIMARY_4XDIM = "rgba(123, 123, 24, 1)"
// export const SECONDARY_4XDIM = "rgba(24, 123, 123, 1)"
// export const TERTIARY_4XDIM = "rgba(123, 24, 123, 1)"
