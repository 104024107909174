import React from "react"
import styled from "styled-components"
import { SECONDARY } from "../../style/colors"
import {
    FONT_SIZE_2XS,
    TITLE_FONT_FAMILY,
    TITLE_LETTER_SPACING,
    TITLE_FONT_WEIGHT,
} from "../../style/fontSettings"
import Button from "../Button"

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const Title = styled.div`
    height: 32px;
    font-size: ${FONT_SIZE_2XS};
    color: ${SECONDARY};
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`

const TitleText = styled.div`
    height: 32px;
    font-family: ${TITLE_FONT_FAMILY};
    font-weight: ${TITLE_FONT_WEIGHT};
    letter-spacing: ${TITLE_LETTER_SPACING};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ListContainer = styled.div`
    flex-grow: 1;
    max-height: calc(
        80vh - 64px
    ); // 80% of viewport height minus padding and title height
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto; // Make it scrollable

    /* Hide scrollbar */
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const PopoverContentContainer = ({
    title,
    list,
    onClick,
    component,
    selectedListItem,
}) => {
    console.log("list", list)
    console.log("selectedListItem", selectedListItem)

    return (
        <ContentContainer>
            <Title>
                <TitleText>{title}</TitleText>
            </Title>
            <ListContainer>
                {list &&
                    list.map((item, index) => (
                        <Button
                            key={index}
                            text={item}
                            height="32px"
                            width="168px"
                            justifyContent="flex-start"
                            isSelected={item === selectedListItem}
                            buttonColor="secondary"
                            onClick={(event) => {
                                onClick(event, item)
                            }}
                        />
                    ))}
                {component && component}
            </ListContainer>
        </ContentContainer>
    )
}

export default PopoverContentContainer
