import styled from "styled-components"
import ProgressBar from "./ProgressBar"

const StyledProgressBar = styled.div`
    width: 100%;
    cursor: pointer;
`

function PlaySongProgressBar({ progress, handleDrag, handleClick }) {
    const handleMouseDown = (e) => {
        document.addEventListener("mousemove", handleDrag)
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", handleDrag)
        })
    }
    return (
        <StyledProgressBar
            className="progress-bar"
            onMouseDown={handleMouseDown}
            onClick={handleClick}
        >
            <ProgressBar progress={progress} />
        </StyledProgressBar>
    )
}

export default PlaySongProgressBar
