import React from "react"
import styled from "styled-components"
import Button from "./Button"

const SongNamesStyled = styled.div`
    flex-grow: 1;
    width: 168px;
    overflow: auto;
    /* Hide scrollbar */
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    margin-left: 16px;
`

const SongNames = ({ songs, selectedSong, setSelectedSong }) => {
    return (
        <SongNamesStyled>
            {songs.map((song) => {
                const isSelected = song.name === selectedSong.name

                return (
                    <Button
                        key={song.name}
                        text={song.name}
                        height="32px"
                        width="168px"
                        justifyContent="flex-start"
                        isSelected={isSelected}
                        buttonColor="secondary"
                        onClick={() => setSelectedSong(song)}
                    />
                )
            })}
        </SongNamesStyled>
    )
}

export default SongNames
