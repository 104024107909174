import React, { useState } from "react"
import styled from "styled-components"
import {
    FaAngleLeft,
    FaAngleRight,
    FaHeart,
    FaComment,
    FaInfo,
} from "react-icons/fa"
import {
    MELOGY_BACKGROUND_DARK_GREY,
    MELOGY_BACKGROUND_XDARK_GREY_DIM,
} from "../../style/colors"
import Popover from "../popover/Popover"
import DonateBitcoin from "../popover/content/DonateBitcoin"
import Contact from "../popover/content/Contact"
import About from "../popover/content/About"
import SongList from "../SongList"
import Button from "../Button"
import PopoverContentContainer from "../popover/PopoverContentContainer"

const SidebarContainer = styled.div`
    z-index: 4;
    display: flex;
    width: 200px;
    position: fixed;
    left: ${({ open }) => (open ? "0px" : "-200px")};
    transition: left 0.3s ease-in-out;
    height: 100%;
    background-color: ${MELOGY_BACKGROUND_DARK_GREY};
`

const SidebarContent = styled.div`
    height: 100%;
    width: 184px;
    overflow: auto;
    display: flex;
    flex-direction: column;
`

const SidebarEdge = styled.div`
    width: 16px;
    height: 100%;
    cursor: pointer;
`

const SidebarSpace = styled.div`
    height: 16px;
    min-height: 16px;
    width: 184px;
`

const SidebarOutsideLayer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${MELOGY_BACKGROUND_XDARK_GREY_DIM};
    z-index: 3;
    display: ${({ open }) => (open ? "block" : "none")};
`

const LeftSidebar = ({
    songs,
    selectedSong,
    setSelectedSong,
    toggleLeftSideBar,
    isOpen,
}) => {
    const [aboutPopoverOpen, setAboutPopoverOpen] = useState(false)
    const [donatePopoverOpen, setDonatePopoverOpen] = useState(false)
    const [contactPopoverOpen, setContactPopoverOpen] = useState(false)

    const toggleAboutPopover = () => {
        setAboutPopoverOpen(!aboutPopoverOpen)
    }

    const toggleDonatePopover = () => {
        setDonatePopoverOpen(!donatePopoverOpen)
    }

    const toggleContactPopover = () => {
        setContactPopoverOpen(!contactPopoverOpen)
    }

    return (
        <>
            <SidebarOutsideLayer open={isOpen} onClick={toggleLeftSideBar} />
            <SidebarContainer open={isOpen}>
                <SidebarContent>
                    <SidebarSpace />
                    <SongList
                        songs={songs}
                        selectedSong={selectedSong}
                        setSelectedSong={setSelectedSong}
                    />
                    <SidebarSpace />
                    <Button
                        text="About"
                        height="32px"
                        width="168px"
                        justifyContent="flex-start"
                        buttonColor="secondary"
                        onClick={toggleAboutPopover}
                        icon={<FaInfo style={{ fontSize: "16px" }} />}
                        margin={"0 0 0 16px"}
                    />
                    <Button
                        text="Donate"
                        height="32px"
                        width="168px"
                        justifyContent="flex-start"
                        buttonColor="secondary"
                        onClick={toggleDonatePopover}
                        icon={<FaHeart style={{ fontSize: "16px" }} />}
                        margin={"0 0 0 16px"}
                    />
                    <Button
                        text="Contact"
                        height="32px"
                        width="168px"
                        justifyContent="flex-start"
                        buttonColor="secondary"
                        onClick={toggleContactPopover}
                        icon={<FaComment style={{ fontSize: "16px" }} />}
                        margin={"0 0 0 16px"}
                    />
                    <SidebarSpace />
                </SidebarContent>
                <SidebarEdge>
                    <Button
                        height="100%"
                        width="16px"
                        justifyContent="flex-start"
                        buttonColor="secondary"
                        onClick={toggleLeftSideBar}
                        // icon={
                        //     isOpen ? (
                        //         <FaAngleLeft style={{ fontSize: "16px" }} />
                        //     ) : (
                        //         <FaAngleRight style={{ fontSize: "16px" }} />
                        //     )
                        // }
                        isToggleSidebarButton
                    />
                </SidebarEdge>
            </SidebarContainer>
            {aboutPopoverOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title="ABOUT"
                            component={<About />}
                        />
                    }
                    onClose={toggleAboutPopover}
                    zIndex={1000}
                />
            )}
            {donatePopoverOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title="DONATE BITCOIN"
                            component={<DonateBitcoin />}
                        />
                    }
                    onClose={toggleDonatePopover}
                    zIndex={1000}
                />
            )}
            {contactPopoverOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title="CONTACT"
                            component={<Contact />}
                        />
                    }
                    onClose={toggleContactPopover}
                    zIndex={1000}
                />
            )}
        </>
    )
}

export default LeftSidebar
