import React, { useState, useEffect } from "react"
import Slider from "@mui/material/Slider"
import styled from "styled-components"
import { FaVolumeOff, FaVolumeMute } from "react-icons/fa"
import Button from "../Button"

const SliderContainer = styled.div`
    height: 160px;
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const SliderVolumeNumberDisplay = styled.div`
    height: 32px;
`

function VolumeSlider({ selectedInstrument, handleVolumeChange, trackNumber }) {
    const { name } = selectedInstrument
    const [localVolume, setLocalVolume] = useState(selectedInstrument.volume)

    // Debounce function
    const debounce = (func, delay) => {
        let timer
        return function (...args) {
            clearTimeout(timer)
            timer = setTimeout(() => func.apply(this, args), delay)
        }
    }

    // Create debounced volume change handler
    const debouncedHandleVolumeChange = debounce(handleVolumeChange, 500)

    // Handle slider value change (for local state update)
    const handleChange = (event, newValue) => {
        setLocalVolume(newValue) // Update local volume state immediately
    }

    // Handle slider value change committed (for debounced parent state update)
    const handleVolumeChangeEnd = (event, newValue) => {
        const instrumentIndex = trackNumber - 1
        debouncedHandleVolumeChange(instrumentIndex, newValue)
    }

    // Update local volume when selectedInstrument's volume changes
    useEffect(() => {
        setLocalVolume(selectedInstrument.volume)
    }, [selectedInstrument.volume])

    // Mute toggle handler
    const toggleMute = () => {
        const instrumentIndex = trackNumber - 1
        const newVolume = localVolume > 0 ? 0 : 0.2
        setLocalVolume(newVolume)
        handleVolumeChange(instrumentIndex, newVolume) // Immediate parent state update
    }

    return (
        <SliderContainer>
            <SliderVolumeNumberDisplay>
                {Math.round(localVolume * 400.0)}
            </SliderVolumeNumberDisplay>
            <Slider
                getAriaLabel={() => `Volume slider for ${name}`}
                value={localVolume}
                orientation="vertical"
                size="small"
                onChange={handleChange}
                onChangeCommitted={handleVolumeChangeEnd}
                valueLabelDisplay="off"
                step={0.0025}
                min={0.0}
                max={0.25}
            />
            <Button
                height="32px"
                width="32px"
                justifyContent="flex-start"
                buttonColor="secondary"
                icon={
                    localVolume > 0 ? (
                        <FaVolumeOff style={{ fontSize: "16px" }} />
                    ) : (
                        <FaVolumeMute style={{ fontSize: "16px" }} />
                    )
                }
                onClick={toggleMute}
            />
        </SliderContainer>
    )
}

export default VolumeSlider
