import React from "react"
import styled from "styled-components"
import { SECONDARY } from "../../../style/colors"
import {
    FONT_SIZE_2XS,
    CODE_FONT_FAMILY,
    CODE_FONT_WEIGHT,
    CODE_LETTER_SPACING,
} from "../../../style/fontSettings"
import pngImage from "../../../resources/MelogyBTCPublicAddress.png"
import { showToastSuccess } from "../../../style/ShowToast"
import Button from "../../Button"
import { FaCopy } from "react-icons/fa"

const QRImageContainer = styled.div`
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: min(calc(80vw - 32px), calc(480px - 32px));
    margin-bottom: 8px;
    margin-top: 8px;
`

const QRImage = styled.img`
    width: 120px;
    height: 120px;
`

const AddressText = styled.div`
    max-width: min(calc(80vw - 32px), calc(480px - 32px));
    font-size: ${FONT_SIZE_2XS};
    letter-spacing: 0.2px;
    color: ${SECONDARY};
    user-select: none;
`

const DonateBitcoinPopoverContent = () => {
    const bitcoinAddress = "bc1q7g3a6t7qgscxt5eph64j0ymepl2sjuarynejp0"

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(bitcoinAddress)
        showToastSuccess(`Bitcoin address copied to clipboard!`, 5000)
    }

    return (
        <>
            <AddressText>
                By supporting me, you help me provide you with more features,
                updates, and a better user experience.
            </AddressText>
            <ButtonContainer>
                <Button
                    text={bitcoinAddress}
                    fontSize={FONT_SIZE_2XS}
                    fontFamily={CODE_FONT_FAMILY}
                    fontWeight={CODE_FONT_WEIGHT}
                    height="32px"
                    width="100%"
                    justifyContent="flex-start"
                    buttonColor="secondary"
                    onClick={handleCopyToClipboard}
                    icon={<FaCopy style={{ fontSize: "16px" }} />}
                    letterSpacing={CODE_LETTER_SPACING}
                    key="copyButton"
                />
            </ButtonContainer>
            <QRImageContainer>
                <QRImage src={pngImage} alt="Image" />
            </QRImageContainer>
        </>
    )
}

export default DonateBitcoinPopoverContent
