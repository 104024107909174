import React, { useState } from "react"
import styled from "styled-components"
import "react-toastify/dist/ReactToastify.css"
import { FaPlus, FaDownload, FaCog, FaBars } from "react-icons/fa"
import { handleWAVDownloadClick } from "../util/PlayerUtil.js"
import Download from "./popover/content/Download"
import Popover from "./popover/Popover"
import Button from "./Button.js"
import PopoverContentContainer from "./popover/PopoverContentContainer.js"

const ControlsContainer = styled.div`
    width: 100%;
    height: 48px;
    max-width: 960px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ControlsBackground = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const DownloadAndCreateContainer = styled.div`
    height: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`

const EmptySpace = styled.div`
    height: 16px;
    width: 100%;
`

const Controls = ({
    selectedSong,
    selectedInstruments,
    createAndFetchMidi,
    toggleLeftSideBar,
    toggleShowStyleSettings,
}) => {
    const [downloadPopoverOpen, setDownloadPopoverOpen] = useState(false)
    const midi = selectedSong ? selectedSong.midi : null

    const toggleDownloadPopover = () => {
        setDownloadPopoverOpen(!downloadPopoverOpen)
    }

    return (
        <>
            {midi && (
                // <ControlsBackroundColor>
                <ControlsContainer>
                    <ControlsBackground>
                        <DownloadAndCreateContainer>
                            <Button
                                height="32px"
                                width="32px"
                                justifyContent="flex-start"
                                buttonColor="tertiary"
                                onClick={toggleLeftSideBar}
                                icon={<FaBars style={{ fontSize: "16px" }} />}
                                margin={"0 0 0 16px"}
                            />
                            <Button
                                height="32px"
                                width="32px"
                                justifyContent="flex-start"
                                buttonColor="tertiary"
                                onClick={toggleDownloadPopover}
                                icon={
                                    <FaDownload style={{ fontSize: "20px" }} />
                                }
                            />
                            <Button
                                height="32px"
                                width="32px"
                                justifyContent="flex-start"
                                buttonColor="tertiary"
                                onClick={createAndFetchMidi}
                                icon={<FaPlus style={{ fontSize: "20px" }} />}
                            />
                            <Button
                                height="32px"
                                width="32px"
                                justifyContent="flex-start"
                                buttonColor="tertiary"
                                onClick={toggleShowStyleSettings}
                                icon={<FaCog style={{ fontSize: "16px" }} />}
                                margin={"0 16px 0 0"}
                            />
                        </DownloadAndCreateContainer>
                        <EmptySpace />
                        {downloadPopoverOpen && (
                            <Popover
                                content={
                                    <PopoverContentContainer
                                        title="DOWNLOAD"
                                        component={
                                            <Download
                                                handleWAVDownloadClick={() =>
                                                    handleWAVDownloadClick(
                                                        midi,
                                                        selectedInstruments
                                                    )
                                                }
                                                selectedSong={selectedSong}
                                            />
                                        }
                                    />
                                }
                                onClose={toggleDownloadPopover}
                            />
                        )}
                    </ControlsBackground>
                </ControlsContainer>
                // </ControlsBackroundColor>
            )}
        </>
    )
}

export default Controls
