export const FONT_FAMILY = "Roboto-Light"

export const TITLE_FONT_FAMILY = "RobotoMono-VariableFont_wght"
export const TITLE_FONT_WEIGHT = 400
export const TITLE_LETTER_SPACING = "2px"

export const CODE_FONT_FAMILY = "RobotoMono-VariableFont_wght"
export const CODE_FONT_WEIGHT = 300
export const CODE_LETTER_SPACING = "0px"

export const BUTTON_FONT_FAMILY = "Roboto-Regular"
export const BUTTON_FONT_WEIGHT = 400
export const BUTTON_LETTER_SPACING = "0.2px"

export const TEXT_FONT_FAMILY = "Roboto-Light"
export const TEXT_FONT_WEIGHT = 300
export const TEXT_LETTER_SPACING = "0.4px"

export const FONT_SIZE_6XS = "12px"
export const FONT_SIZE_4XS = "14px"
export const FONT_SIZE_3XS = "15px"
export const FONT_SIZE_2XS = "18px"
export const FONT_SIZE_XS = "20px"
export const FONT_SIZE_S = "24px"
export const FONT_SIZE_M = "32px"
export const FONT_SIZE_L = "40px"
export const FONT_SIZE_XL = "48px"
export const FONT_SIZE_2XL = "56px"
