import React from "react"
import styled from "styled-components"
import { MELOGY_TRANSPARENT, SECONDARY } from "../../style/colors"
import StyleSetting from "./StyleSetting"

const TracksContainer = styled.div`
    width: ${({ isScrollVisible }) =>
        isScrollVisible ? "calc(100% - 16px)" : "100%"};
    max-width: 960px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ isScrollVisible }) =>
        isScrollVisible ? "flex-start" : "center"};
`

const TracksBackground = styled.div`
    width: 100%;
    height: 100%;
    color: ${SECONDARY};
    background-color: ${MELOGY_TRANSPARENT};
`

function StyleSettings({
    styleSettings,
    updateStyleSettings,
    isScrollVisible,
}) {
    return (
        <TracksContainer isScrollVisible={isScrollVisible}>
            <TracksBackground>
                {styleSettings.map((styleSetting) => (
                    <StyleSetting
                        key={styleSetting.name}
                        styleSetting={styleSetting}
                        updateStyleSettings={updateStyleSettings}
                    />
                ))}
            </TracksBackground>
        </TracksContainer>
    )
}

export default StyleSettings
