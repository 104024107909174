import React, { useState, useEffect } from "react"
import "./App.css"
import styled from "styled-components"
import { MELOGY_BACKGROUND_XDARK_GREY } from "./style/colors"
import GlobalStyle from "./style/GlobalStyle"
import { ThemeProvider } from "@mui/material/styles"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import muiTheme from "./style/muiTheme"
import LeftSidebar from "./components/sidebar/LeftSidebar"
import Main from "./components/Main"
import { CreateSongProgressBarContext } from "./contexts/CreateSongProgressBarContext"
import CreateSongProgressBar from "./components/progressbar/CreateSongProgressBar"
import { StyledToastContainer } from "./style/StyledToastContainer"
import {
    setViewportHeight,
    createAndFetchMidi,
    updateKeySetting,
} from "./util/AppUtil"
import { showToastInfo } from "./style/ShowToast"
import { defaultStyleSettings } from "./style/defaultStyleSettings"

const AppContainer = styled.div`
    height: calc(var(--vh, 1vh) * 100);
    background-color: ${MELOGY_BACKGROUND_XDARK_GREY};
    display: flex;
`

function App() {
    const [leftSideBarIsOpen, setLeftSideBarIsOpen] = useState(false)
    const [showStyleSettings, setShowStyleSettings] = useState(false)
    const maxNumberOfSongs = 50
    const numberOfSongsToRemoveToFreeUpSpaceAtOneTime = 10
    const [styleSettings, setStyleSettings] = useState(defaultStyleSettings)
    const [selectedSong, setSelectedSong] = useState(null)
    const [progress, setProgress] = useState(0)
    const [songs, setSongs] = useState([])
    const [selectedInstruments, setSelectedInstruments] = React.useState([
        { category: "Samples", name: "Trumpet", volume: 0.2 },
        { category: "Samples", name: "Violin", volume: 0.12 },
        { category: "Samples", name: "Cello", volume: 0.14 },
        { category: "Monosynth", name: "Bass guitar", volume: 0.2 },
    ])
    let songCounter = 1

    useEffect(() => {
        setViewportHeight()
        window.addEventListener("resize", setViewportHeight)

        return () => window.removeEventListener("resize", setViewportHeight)
    }, [])

    const handleCreateAndFetchMidi = () => {
        createAndFetchMidi(setProgress, setSong, styleSettings)
    }

    const setSong = (midi) => {
        // Exit early if there's no MIDI data
        if (!midi) return

        // Generate a unique name for the song
        let name = `Song ${songCounter}`
        while (!isNameUnique(name)) {
            songCounter++
            name = `Song ${songCounter}`
        }

        // Create the new song
        const newSong = { name: name, midi: midi }
        setSelectedSong(newSong)

        notifySongLimit()

        // Remove the ten oldest songs to free up space when reaching 50 songs
        if (songs.length >= maxNumberOfSongs) {
            showToastInfo(
                "Removed the ten oldest songs to make room for new ones.",
                5000
            )
            setSongs((prevSongs) => [
                ...prevSongs.slice(numberOfSongsToRemoveToFreeUpSpaceAtOneTime),
                newSong,
            ])
        } else {
            // Add the new song to the list
            setSongs((prevSongs) => [...prevSongs, newSong])
        }
    }

    const notifySongLimit = () => {
        const notifySongLimit =
            songs.length ===
            maxNumberOfSongs - numberOfSongsToRemoveToFreeUpSpaceAtOneTime - 1

        if (notifySongLimit) {
            showToastInfo(
                `When reaching ${maxNumberOfSongs} songs, the ${numberOfSongsToRemoveToFreeUpSpaceAtOneTime} oldest will be removed to free up space.`,
                5000
            )
        }
    }

    const selectInstrument = (name, trackNumber, selectedCategory) => {
        if (trackNumber && selectedCategory && name) {
            const updatedInstruments = [...selectedInstruments]
            const existingInstrument = updatedInstruments[trackNumber - 1]

            updatedInstruments[trackNumber - 1] = {
                category: selectedCategory,
                name: name,
                // Use the existing volume if available, otherwise default to 0.2
                volume: existingInstrument ? existingInstrument.volume : 0.2,
            }

            setSelectedInstruments(updatedInstruments)
        }
    }

    const handleVolumeChange = (instrumentIndex, newVolume) => {
        const updatedInstruments = [...selectedInstruments]

        if (updatedInstruments[instrumentIndex]) {
            updatedInstruments[instrumentIndex] = {
                ...updatedInstruments[instrumentIndex],
                volume: newVolume,
            }
        }

        setSelectedInstruments(updatedInstruments)
    }

    const isNameUnique = (nameToCheck) =>
        !songs.some((song) => song.name === nameToCheck)

    const setSongName = (newName) => {
        // Update selectedSong
        const updatedSelectedSong = { ...selectedSong, name: newName }
        setSelectedSong(updatedSelectedSong)

        // Update the songs array
        const updatedSongs = songs.map((song) =>
            song.name === selectedSong.name ? updatedSelectedSong : song
        )

        setSongs(updatedSongs)
    }

    const value = React.useMemo(
        () => ({
            progress,
            setProgress,
        }),
        [progress]
    )

    const toggleLeftSideBar = () => {
        setLeftSideBarIsOpen(!leftSideBarIsOpen)
    }

    const toggleShowStyleSettings = () => {
        setShowStyleSettings(!showStyleSettings)
    }

    const updateStyleSettings = (settingName, newValue) => {
        setStyleSettings((currentSettings) => {
            if (settingName === "key") {
                return updateKeySetting(currentSettings, newValue)
            } else if (
                settingName === "intensityOfChords" ||
                settingName === "intensityOfMelody" ||
                settingName === "motiveComplexityOfMelody" ||
                settingName === "motiveComplexityOfChords"
            ) {
                return currentSettings.map((setting) =>
                    setting.name === settingName
                        ? {
                              ...setting,
                              lowerValue: newValue[0],
                              higherValue: newValue[1],
                          }
                        : setting
                )
            }

            // For other settings, simply update the value
            return currentSettings.map((setting) =>
                setting.name === settingName
                    ? { ...setting, value: newValue }
                    : setting
            )
        })
    }

    return (
        <>
            <GlobalStyle />
            <ThemeProvider theme={muiTheme}>
                <CreateSongProgressBarContext.Provider value={value}>
                    <CreateSongProgressBar progress={progress} />
                    <AppContainer>
                        <StyledToastContainer
                            position={toast.POSITION.TOP_RIGHT}
                        />
                        <LeftSidebar
                            songs={songs}
                            selectedSong={selectedSong}
                            setSelectedSong={setSelectedSong}
                            toggleLeftSideBar={toggleLeftSideBar}
                            isOpen={leftSideBarIsOpen}
                        />
                        <Main
                            songs={songs}
                            setSelectedSong={setSelectedSong}
                            selectedSong={selectedSong}
                            selectedInstruments={selectedInstruments}
                            selectInstrument={selectInstrument}
                            setSongName={setSongName}
                            isNameUnique={isNameUnique}
                            createAndFetchMidi={handleCreateAndFetchMidi}
                            toggleLeftSideBar={toggleLeftSideBar}
                            toggleShowStyleSettings={toggleShowStyleSettings}
                            showStyleSettings={showStyleSettings}
                            styleSettings={styleSettings}
                            updateStyleSettings={updateStyleSettings}
                            handleVolumeChange={handleVolumeChange}
                        />
                    </AppContainer>
                </CreateSongProgressBarContext.Provider>
            </ThemeProvider>
        </>
    )
}

export default App
