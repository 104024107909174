import {
    SECONDARY_HIGHLIGHT,
    PRIMARY_HIGHLIGHT,
    TERTIARY_HIGHLIGHT,
    PRIMARY,
    SECONDARY,
    TERTIARY,
    PRIMARY_XHIGHLIGHT,
    SECONDARY_XHIGHLIGHT,
    TERTIARY_XHIGHLIGHT,
} from "../style/colors"

export const getButtonColor = (buttonColor, isSelected) => {
    if (buttonColor === "primary") {
        return isSelected ? PRIMARY_HIGHLIGHT : PRIMARY
    } else if (buttonColor === "secondary") {
        return isSelected ? SECONDARY_HIGHLIGHT : SECONDARY
    } else if (buttonColor === "tertiary") {
        return isSelected ? TERTIARY_HIGHLIGHT : TERTIARY
    }

    return SECONDARY // default
}

export const getButtonHoverColor = (buttonColor) => {
    switch (buttonColor) {
        case "primary":
            return PRIMARY_HIGHLIGHT
        case "secondary":
            return SECONDARY_HIGHLIGHT
        case "tertiary":
            return TERTIARY_HIGHLIGHT
        default:
            return SECONDARY_HIGHLIGHT // default
    }
}

export const getButtonActiveColor = (buttonColor) => {
    switch (buttonColor) {
        case "primary":
            return PRIMARY_XHIGHLIGHT
        case "secondary":
            return SECONDARY_XHIGHLIGHT
        case "tertiary":
            return TERTIARY_XHIGHLIGHT
        default:
            return SECONDARY_XHIGHLIGHT // default
    }
}
