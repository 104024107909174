import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Midi } from "@tonejs/midi"
import QuarterNoteIcon from "../components/QuarterNoteIcon"
import { MELOGY_TRANSPARENT, SECONDARY } from "../style/colors"
import {
    BUTTON_FONT_FAMILY,
    BUTTON_FONT_WEIGHT,
    BUTTON_LETTER_SPACING,
    FONT_SIZE_4XS,
    FONT_SIZE_M,
    TITLE_FONT_FAMILY,
    TITLE_FONT_WEIGHT,
} from "../style/fontSettings"
import { showToastError } from "../style/ShowToast"
import Popover from "./popover/Popover"
import Button from "./Button"
import PopoverContentContainer from "./popover/PopoverContentContainer"
import { getKey, getKeys, getRoots, getRoot } from "../util/StyleSettingUtil"

const SettingsContainer = styled.div`
    width: 100%;
    max-width: 960px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* Hide scrollbar */
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const SettingsBackGround = styled.div`
    color: ${SECONDARY};
    background-color: ${MELOGY_TRANSPARENT};
    width: 80%;
    height 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`

const SongName = styled.input`
    font-family: ${BUTTON_FONT_FAMILY};
    font-weight: ${BUTTON_FONT_WEIGHT};
    letter-spacing: ${BUTTON_LETTER_SPACING};
    font-size: ${FONT_SIZE_M};
    height: 48px;
    width: 100%;
    color: ${SECONDARY};
    background-color: ${MELOGY_TRANSPARENT};
    border: none;
    text-align: center;

    &:focus {
        outline: none;
    }
`

const SelectRootAndKeyButtonsContainer = styled.div`
    height: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const TempoDisplay = styled.div`
    height: 32px;
    width: auto;
    margin-left: 16px;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const TempoDisplayEquals = styled.div`
    font-size: ${FONT_SIZE_4XS};
    font-family: ${TITLE_FONT_FAMILY};
    font-weight: ${TITLE_FONT_WEIGHT};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
`

const TempoDisplayNumber = styled.div`
    font-size: ${FONT_SIZE_4XS};
    font-family: ${TITLE_FONT_FAMILY};
    font-weight: ${TITLE_FONT_WEIGHT};
`

function Settings({
    selectedSong,
    setSongName,
    isNameUnique,
    styleSettings,
    updateStyleSettings,
}) {
    const [tempSongName, setTempSongName] = useState(
        selectedSong ? selectedSong.name : ""
    )
    const [selectKeyPopoverIsOpen, setSelectKeyPopover] = useState(false)
    const [selectRootPopoverIsOpen, setSelectRootPopover] = useState(false)
    const keys = getKeys(styleSettings)
    const key = getKey(styleSettings)
    const roots = getRoots(styleSettings)
    const root = getRoot(styleSettings)
    const [tempo, setTempo] = useState(null)

    useEffect(() => {
        if (selectedSong) {
            setTempSongName(selectedSong.name)
        }
    }, [selectedSong])

    const toggleSelectKeyPopover = () => {
        setSelectKeyPopover((prev) => !prev)
    }

    const toggleSelectRootPopover = () => {
        setSelectRootPopover((prev) => !prev)
    }

    const validateAndSetSongName = () => {
        // If the song name is the same as before, no need to validate
        if (tempSongName === selectedSong.name) {
            return
        }

        // Step 1: Whitelist allowed characters (alphanumeric and some special characters)
        const regex = /^[a-zA-Z0-9.\-_ !?]+$/

        if (!regex.test(tempSongName)) {
            showToastError("Invalid characters in the song name", 5000)
            setSongName(selectedSong.name)
            return
        }

        // Step 2: Length Check
        if (tempSongName.length > 42) {
            showToastError("Song name should be at most 42 characters", 5000)
            setSongName(selectedSong.name)
            return
        }

        // Step 3: Check if the song name is unique
        if (isNameUnique(tempSongName)) {
            setSongName(tempSongName)
        } else {
            showToastError("Song name should be unique", 5000)
            setSongName(selectedSong.name)
        }
    }

    const handleSelectKey = (event) => {
        const selectedKey = event.target.innerText

        if (selectedKey && keys.includes(selectedKey)) {
            updateStyleSettings("key", selectedKey)
            toggleSelectKeyPopover()
        }
    }

    const handleSelectRoot = (event) => {
        const selectedRoot = event.target.innerText

        if (selectedRoot && roots.includes(selectedRoot)) {
            updateStyleSettings("root", selectedRoot)
            toggleSelectRootPopover()
        }
    }

    useEffect(() => {
        if (selectedSong) {
            const tempoEvents = selectedSong.midi.header.tempos
            if (tempoEvents.length > 0) {
                setTempo(Math.round(tempoEvents[0].bpm))
            }
        }
    }, [selectedSong])

    return (
        <>
            {selectedSong && (
                <SettingsContainer>
                    <SettingsBackGround>
                        <>
                            <SongName
                                value={tempSongName}
                                onChange={(e) =>
                                    setTempSongName(e.target.value)
                                }
                                onBlur={validateAndSetSongName}
                            />
                            <SelectRootAndKeyButtonsContainer>
                                <TempoDisplay>
                                    <QuarterNoteIcon />
                                    <TempoDisplayEquals>=</TempoDisplayEquals>
                                    <TempoDisplayNumber>
                                        {tempo}
                                    </TempoDisplayNumber>
                                </TempoDisplay>
                                <Button
                                    text={root}
                                    fontSize={FONT_SIZE_4XS}
                                    fontFamily={TITLE_FONT_FAMILY}
                                    fontWeight={TITLE_FONT_WEIGHT}
                                    height="32px"
                                    width={root == "Random" ? "90px" : "34px"}
                                    justifyContent="flex-end"
                                    isSelected={selectRootPopoverIsOpen}
                                    buttonColor="secondary"
                                    onClick={toggleSelectRootPopover}
                                    letterSpacing="3px"
                                    textTransform="uppercase"
                                    textAlign="center"
                                />
                                <Button
                                    text={key}
                                    fontSize={FONT_SIZE_4XS}
                                    fontFamily={TITLE_FONT_FAMILY}
                                    fontWeight={TITLE_FONT_WEIGHT}
                                    height="32px"
                                    width="50%"
                                    justifyContent="flex-start"
                                    isSelected={selectKeyPopoverIsOpen}
                                    buttonColor="secondary"
                                    onClick={toggleSelectKeyPopover}
                                    letterSpacing="3px"
                                    textTransform="uppercase"
                                />
                            </SelectRootAndKeyButtonsContainer>
                        </>
                    </SettingsBackGround>
                </SettingsContainer>
            )}
            {selectRootPopoverIsOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title="ROOT"
                            list={roots}
                            onClick={handleSelectRoot}
                        />
                    }
                    onClose={toggleSelectRootPopover}
                    zIndex={1002}
                />
            )}
            {selectKeyPopoverIsOpen && (
                <Popover
                    content={
                        <PopoverContentContainer
                            title="KEY"
                            list={keys}
                            onClick={handleSelectKey}
                        />
                    }
                    onClose={toggleSelectKeyPopover}
                    zIndex={1002}
                />
            )}
        </>
    )
}

export default Settings
