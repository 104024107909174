import {
    MELOGY_BACKGROUND_XDARK_GREY,
    PRIMARY_HIGHLIGHT,
    SECONDARY_HIGHLIGHT,
    TERTIARY_HIGHLIGHT,
    PRIMARY,
    SECONDARY,
    TERTIARY,
    PRIMARY_DIM,
    SECONDARY_DIM,
    TERTIARY_DIM,
    PRIMARY_XDIM,
    SECONDARY_XDIM,
    TERTIARY_XDIM,
    PRIMARY_2XDIM,
    SECONDARY_2XDIM,
    TERTIARY_2XDIM,
    PRIMARY_3XDIM,
    SECONDARY_3XDIM,
    TERTIARY_3XDIM,
    MELOGY_TRANSPARENT,
} from "../style/colors"

export const logoColors = [
    PRIMARY_HIGHLIGHT,
    SECONDARY_HIGHLIGHT,
    TERTIARY_HIGHLIGHT,
    PRIMARY,
    SECONDARY,
    TERTIARY,
    PRIMARY_DIM,
    SECONDARY_DIM,
    TERTIARY_DIM,
    PRIMARY_XDIM,
    SECONDARY_XDIM,
    TERTIARY_XDIM,
    PRIMARY_2XDIM,
    SECONDARY_2XDIM,
    TERTIARY_2XDIM,
    PRIMARY_3XDIM,
    SECONDARY_3XDIM,
    TERTIARY_3XDIM,
]

export const enterFullScreen = () => {
    let elem = document.documentElement

    if (elem.requestFullscreen) {
        elem.requestFullscreen()
    } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen()
    }
}

export const exitFullScreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari & Opera */
        document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen()
    }
}
