import { createGlobalStyle } from "styled-components"
import { FONT_FAMILY } from "./fontSettings"
import { MELOGY_BACKGROUND_XDARK_GREY } from "./colors"

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${FONT_FAMILY};
    background-color: ${MELOGY_BACKGROUND_XDARK_GREY};
    margin: 0;
    padding: 0;
    overflow-x: hidden; // hides horizontal scroll if not needed
  }
`

export default GlobalStyle
