export const TRUMPET = {
    A3: "A3.mp3",
    A5: "A5.mp3",
    
    "A#4": "As4.mp3",

    C4: "C4.mp3",
    C6: "C6.mp3",

    D5: "D5.mp3",

    F3: "F3.mp3",
    F4: "F4.mp3",
    F5: "F5.mp3",

    G4: "G4.mp3",
}