import React from "react"
import { FaMusic, FaWaveSquare } from "react-icons/fa"
import {
    showToastInfo,
    showToastSuccess,
    showToastError,
} from "../../../style/ShowToast"
import Button from "../../Button"

const DownloadPopoverContent = ({ handleWAVDownloadClick, selectedSong }) => {
    const handleMidiDownloadClick = () => {
        try {
            const midi = selectedSong ? selectedSong.midi : null
            const toastInfo = showToastInfo(
                "Preparing your MIDI file for download..."
            )

            if (!midi) {
                throw new Error("MIDI file not loaded yet.")
            }

            // Convert the midi object back to a binary blob
            const midiBytes = midi.toArray()
            const blob = new Blob([midiBytes], { type: "audio/midi" })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.style.display = "none"
            a.href = url
            a.download = `${selectedSong.name}.mid`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)

            toastInfo.dismiss()
            showToastSuccess("Your MIDI file is ready for download!", 5000)
        } catch (e) {
            console.error("Download failed:", e)
            showToastError(
                "An error occurred while preparing your MIDI file.",
                5000
            )
        }
    }

    return (
        <>
            <Button
                text="Wav"
                height="32px"
                width="168px"
                justifyContent="flex-start"
                buttonColor="secondary"
                onClick={handleWAVDownloadClick}
                icon={<FaWaveSquare style={{ fontSize: "16px" }} />}
                margin="none"
            />
            <Button
                text="Midi"
                height="32px"
                width="168px"
                justifyContent="flex-start"
                buttonColor="secondary"
                onClick={handleMidiDownloadClick}
                icon={<FaMusic style={{ fontSize: "16px" }} />}
                margin="none"
            />
        </>
    )
}

export default DownloadPopoverContent
