import * as React from "react"
import styled from "styled-components"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

const CheckboxGroupContainer = styled.div`
    height: 16px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    align-items: center;
`

function CheckboxGroup({ styleSetting, updateStyleSettings }) {
    return (
        <CheckboxGroupContainer>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="7th"
                />
                <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="7th"
                />
                <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="7th"
                />
                <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="7th"
                />
            </FormGroup>
        </CheckboxGroupContainer>
    )
}

export default CheckboxGroup
