import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import {
    MELOGY_BACKGROUND_MEDIUM_GREY,
    MELOGY_BACKGROUND_XDARK_GREY_DIM,
} from "../../style/colors"

const PopoverContainer = styled.div`
    background-color: ${MELOGY_BACKGROUND_MEDIUM_GREY};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${(props) => props.zIndex};

    max-height: min(80vh, 480px);
    max-width: min(80vw, 480px);
`

const PopoverContent = styled.div`
    padding: 16px;
`

const PopoverLayer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${MELOGY_BACKGROUND_XDARK_GREY_DIM};
    z-index: ${(props) => props.zIndex};
`

const Popover = ({ content, onClose, zIndex }) => {
    const popoverRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            console.log("handleClickoutside")
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target)
            ) {
                onClose()
            }
        }

        const handleEscapeKey = (event) => {
            if (event.key === "Escape") {
                onClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        document.addEventListener("keydown", handleEscapeKey)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
            document.removeEventListener("keydown", handleEscapeKey)
        }
    }, [onClose])

    const stopPropagation = (e) => {
        e.stopPropagation() // Stop click event from propagating to parent elements
    }

    return (
        <>
            {<PopoverLayer zIndex={zIndex - 1} onClick={stopPropagation} />}
            <PopoverContainer
                ref={popoverRef}
                zIndex={zIndex}
                onClick={stopPropagation}
            >
                <PopoverContent>{content}</PopoverContent>
            </PopoverContainer>
        </>
    )
}

export default Popover
